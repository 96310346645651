<template>
  <div class="recordList-container">
      <div class="record-box">
        <div class="record-box-list">
          <div class="record-box-list-title">
            <div>开票抬头</div>
            <div>开票金额</div>
            <div>开票状态</div>
            <div>申请开票时间</div>
            <div>操作</div>
          </div>
          <div class="record-box-list-detail">
            <div v-for="(v,k) in list" :key="k">
              <div :title="v.invoiceName">{{v.invoiceName}}</div>
              <div>￥{{v.invoiceApplyAmt}}</div>
              <div>{{status[v.applyStatus]}}</div>
              <div>{{v.createTime}}</div>
              <div @click="watchDetail(v.invoiceApplyCode)">查看详情</div>
            </div>
          </div>
          <div class="record-box-page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage1"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total">
          </el-pagination>
          </div>
        </div>
      </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
export default {
  name: 'recordList',
  data(){
    return{
      total:0,
      currentPage1: 1,
      pageSize: 10,
      list:[],
      status:{
        '0':'开票中',
        '1': '开票审核通过',
        '2': '开票审核不通过'
      },
    }
  },
  components: {

  },
  methods:{
    watchDetail(val,val2){
      this.$router.push({
        path:'/personal/recordDetail',
        query:{
          code:val,
          edit:1
        }
      })
    },
    getdata(){
      this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/allapply',{
                    pageSize:this.pageSize,
                    pageNum:this.currentPage1
              },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                // console.log(res)
                if(res.data.code == "200"){
                  this.list = res.data.data.list;
                  this.total= res.data.data.total;
                }
              })
    },
    handleSizeChange(val) {
              this.pageSize = val;
              this.getdata();
            },
    handleCurrentChange(val) {
          this.currentPage1 = val;
          this.getdata();
    },
  },
  mounted(){
    this.getdata();
  }
}

</script>

<style scoped>
  .record-box{
    width: 1015px;
    float: left;
    margin: 12px 0 100px 16px;
    background-color: #fff;
    padding-top: 28px;
    min-height: 950px;
    box-sizing: border-box;
  }
  .record-box-top{
    width: 650px;
    margin: 0px 0px 12px 182px;
    border-bottom: 1px solid #eee;
    height: 28px;
    font-family: "PingFangSC";
    font-size: 14px;
    cursor: pointer;
    color: #666;
  }
  .record-box-list-title{
    height: 36px;
    width: 655px;
    line-height: 36px;
    border:1px solid #eee;
    margin: 0 auto;
    background-color: #fafafa;
    box-sizing: border-box;
  }
  .record-box-list-title>div{
    width: 129px;
    text-align: center;
    float: left;
    font-family: "PingFangSC";
    font-size: 14px;
    font-weight: 600;
    color: #333333;
  }
  .record-box-list-detail{
    height: 490px;
    width: 655px;
    margin:0 auto;
  }
  .record-box-list-detail>div{
    height: 36px;
    line-height: 36px;
    border: 1px solid #eee;
    border-top: none;
    box-sizing: border-box;
  }
  .record-box-list-detail>div>div{
    float: left;
    width: 130px;
    text-align: center;
    font-family: "PingFangSC";
    font-size: 14px;
    font-weight: 100;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .record-box-list-detail>div>div:nth-of-type(5){
    color: #EB5E00;
    cursor: pointer;
  }
  .record-box-page{
    width: 400px;
    text-align: center;
    margin: 0 auto 50px;
  }
</style>
